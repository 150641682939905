// extracted by mini-css-extract-plugin
export var root = "PlasmicRatings-module--root--2Ij4H";
export var freeBox = "PlasmicRatings-module--freeBox--1toHi";
export var svg__rcDuZ = "PlasmicRatings-module--svg__rcDuZ--3AxQR";
export var svg__oOcY = "PlasmicRatings-module--svg__oOcY--esLJy";
export var svg__uzGP = "PlasmicRatings-module--svg__uzGP--323T7";
export var svg__iIIrH = "PlasmicRatings-module--svg__iIIrH--1zOhP";
export var svg___3N5P = "PlasmicRatings-module--svg___3N5P--2f3PL";
export var svg__vjXrE = "PlasmicRatings-module--svg__vjXrE--_cCFg";
export var svg___6Lnkj = "PlasmicRatings-module--svg___6Lnkj--3CTW-";
export var svg__ywjaw = "PlasmicRatings-module--svg__ywjaw--3YZOz";
export var svg__ugRyk = "PlasmicRatings-module--svg__ugRyk--2JN5E";
export var svg__zfl5S = "PlasmicRatings-module--svg__zfl5S--1QG79";
export var slotNumReviews = "PlasmicRatings-module--slotNumReviews--jmHdX";