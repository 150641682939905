// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--ESRAt";
export var freeBox__uzC = "PlasmicFooter-module--freeBox__uzC--3MUFe";
export var freeBox__cyfV = "PlasmicFooter-module--freeBox__cyfV--353u5";
export var freeBox__vHl0G = "PlasmicFooter-module--freeBox__vHl0G--1txPz";
export var freeBox__llw4O = "PlasmicFooter-module--freeBox__llw4O--2U1Fu";
export var link__qw5WR = "PlasmicFooter-module--link__qw5WR--Jj0ms";
export var link___4XyZu = "PlasmicFooter-module--link___4XyZu--2b-Va";
export var link__rau6L = "PlasmicFooter-module--link__rau6L--yeYas";
export var link__pfOcj = "PlasmicFooter-module--link__pfOcj--2AfBK";
export var freeBox___9WHwR = "PlasmicFooter-module--freeBox___9WHwR--3npOu";
export var freeBox__t6RIh = "PlasmicFooter-module--freeBox__t6RIh--3zFx_";
export var freeBox__q7RaT = "PlasmicFooter-module--freeBox__q7RaT--15aEN";
export var link__dUjo = "PlasmicFooter-module--link__dUjo--21wqd";
export var link__liJdp = "PlasmicFooter-module--link__liJdp--3omZE";
export var link__bAm8S = "PlasmicFooter-module--link__bAm8S--3b3u0";
export var link__qY1Cb = "PlasmicFooter-module--link__qY1Cb--39bO3";
export var link__awLf = "PlasmicFooter-module--link__awLf--1uaC0";
export var freeBox___9XcZd = "PlasmicFooter-module--freeBox___9XcZd--2gWd_";
export var freeBox__fOcEs = "PlasmicFooter-module--freeBox__fOcEs--M-9fs";
export var freeBox__mTsO4 = "PlasmicFooter-module--freeBox__mTsO4--DTimp";
export var link__oeSjR = "PlasmicFooter-module--link__oeSjR--_alm9";
export var link___3L1Mk = "PlasmicFooter-module--link___3L1Mk--3MwLt";
export var link__jd8DS = "PlasmicFooter-module--link__jd8DS--1MhzW";
export var link__f9P61 = "PlasmicFooter-module--link__f9P61--2NmWT";
export var link__yx55 = "PlasmicFooter-module--link__yx55--3gwei";
export var freeBox___4TTjl = "PlasmicFooter-module--freeBox___4TTjl--1_pv7";
export var freeBox__nqP2G = "PlasmicFooter-module--freeBox__nqP2G--2rNtM";
export var freeBox__ajBVt = "PlasmicFooter-module--freeBox__ajBVt--FyMJv";
export var freeBox__y8F0G = "PlasmicFooter-module--freeBox__y8F0G--3kLb6";
export var textbox = "PlasmicFooter-module--textbox--1_3KR";
export var iconLink__krCu = "PlasmicFooter-module--iconLink__krCu--2gG72";
export var svg___5KzOd = "PlasmicFooter-module--svg___5KzOd--2680r";
export var freeBox__tNvaM = "PlasmicFooter-module--freeBox__tNvaM--Gi8ep";
export var freeBox__tsMaW = "PlasmicFooter-module--freeBox__tsMaW--dGkOw";
export var iconLink__ajuMw = "PlasmicFooter-module--iconLink__ajuMw--36BPT";
export var svg__zkm4A = "PlasmicFooter-module--svg__zkm4A--2MAnP";
export var iconLink__fL3HK = "PlasmicFooter-module--iconLink__fL3HK--17l7I";
export var svg__hGvut = "PlasmicFooter-module--svg__hGvut--3SYQq";
export var iconLink__mt0S4 = "PlasmicFooter-module--iconLink__mt0S4--3wavK";
export var svg___8YkA = "PlasmicFooter-module--svg___8YkA--3kEg5";
export var freeBox__h7BFt = "PlasmicFooter-module--freeBox__h7BFt--3zVlx";
export var freeBox___241Km = "PlasmicFooter-module--freeBox___241Km--1Ieja";
export var link__uEVa = "PlasmicFooter-module--link__uEVa--2PEyE";
export var link__wkvms = "PlasmicFooter-module--link__wkvms--TG1De";