// extracted by mini-css-extract-plugin
export var root = "PlasmicLinkButton-module--root--3s-pU";
export var root__type_solidOrange = "PlasmicLinkButton-module--root__type_solidOrange--3UEPI";
export var root__type_solidGray = "PlasmicLinkButton-module--root__type_solidGray--eHquQ";
export var root__type_outlineGray = "PlasmicLinkButton-module--root__type_outlineGray--J-Zin";
export var root__size_small = "PlasmicLinkButton-module--root__size_small--3YbU1";
export var root__type_blankOrange = "PlasmicLinkButton-module--root__type_blankOrange--2EAT9";
export var slotText__type_solidOrange = "PlasmicLinkButton-module--slotText__type_solidOrange--3wgJL";
export var slotText__type_solidGray = "PlasmicLinkButton-module--slotText__type_solidGray--3ZAZr";
export var slotText__type_blankOrange = "PlasmicLinkButton-module--slotText__type_blankOrange--2HyJh";
export var slotText__type_outlineGray = "PlasmicLinkButton-module--slotText__type_outlineGray--1X5CM";
export var slotText__type_blankGray = "PlasmicLinkButton-module--slotText__type_blankGray--si9fC";
export var root__type_blankGray = "PlasmicLinkButton-module--root__type_blankGray--23rq9";