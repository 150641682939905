// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCard-module--root--1U_3p";
export var freeBox__feHqa = "PlasmicProductCard-module--freeBox__feHqa--3_DQH";
export var img__cA4AH = "PlasmicProductCard-module--img__cA4AH--3-Ypj";
export var __wab_imgSpacer = "PlasmicProductCard-module--__wab_img-spacer--3f1Hg";
export var freeBox__tvF8V = "PlasmicProductCard-module--freeBox__tvF8V--3RGLN";
export var freeBox__s1Vn = "PlasmicProductCard-module--freeBox__s1Vn--3EDvW";
export var freeBox__rjDpN = "PlasmicProductCard-module--freeBox__rjDpN--Fxo_I";
export var svg__vyBBq = "PlasmicProductCard-module--svg__vyBBq--3VpCv";
export var freeBox__kdUpo = "PlasmicProductCard-module--freeBox__kdUpo--A1CxZ";
export var svg__dkkhb = "PlasmicProductCard-module--svg__dkkhb--2Aqgj";
export var svg__ju3Ui = "PlasmicProductCard-module--svg__ju3Ui--1I0Nt";
export var ratings__uPw1J = "PlasmicProductCard-module--ratings__uPw1J--3ZAD5";
export var freeBox__u7SfW = "PlasmicProductCard-module--freeBox__u7SfW--nwZfJ";
export var slotTitle = "PlasmicProductCard-module--slotTitle--3xTy8";
export var slotPrice = "PlasmicProductCard-module--slotPrice--2FyM4";