// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCarousel-module--root--1KCqq";
export var freeBox__dyqw4 = "PlasmicProductCarousel-module--freeBox__dyqw4--2MYUI";
export var freeBox__yh8QQ = "PlasmicProductCarousel-module--freeBox__yh8QQ--rMxVb";
export var freeBox__wfKyq = "PlasmicProductCarousel-module--freeBox__wfKyq--3EqI9";
export var freeBox__jyBai = "PlasmicProductCarousel-module--freeBox__jyBai--3RXMK";
export var freeBox__q7PiL = "PlasmicProductCarousel-module--freeBox__q7PiL--i35Fh";
export var freeBox__ax7Yw = "PlasmicProductCarousel-module--freeBox__ax7Yw--3Zbz0";
export var freeBox__wybS = "PlasmicProductCarousel-module--freeBox__wybS--1L6-2";
export var freeBox__k8QkF = "PlasmicProductCarousel-module--freeBox__k8QkF--3Rn3P";
export var freeBox___77NcD = "PlasmicProductCarousel-module--freeBox___77NcD--CNsqC";
export var freeBox__npBcU = "PlasmicProductCarousel-module--freeBox__npBcU--1YQjj";
export var linkButton___8DdpW = "PlasmicProductCarousel-module--linkButton___8DdpW--16Qy_";
export var linkButton__t2Wud = "PlasmicProductCarousel-module--linkButton__t2Wud--dnGzk";
export var freeBox___2SGyr = "PlasmicProductCarousel-module--freeBox___2SGyr--19epl";
export var img = "PlasmicProductCarousel-module--img--1svtk";
export var __wab_imgSpacer = "PlasmicProductCarousel-module--__wab_img-spacer--3Ap13";
export var freeBox__v4I8 = "PlasmicProductCarousel-module--freeBox__v4I8--QHQTM";
export var svg___3L04J = "PlasmicProductCarousel-module--svg___3L04J--2yuF1";
export var svg___4BaQk = "PlasmicProductCarousel-module--svg___4BaQk--3l2hO";
export var svg__rIlIj = "PlasmicProductCarousel-module--svg__rIlIj--1nDZ_";