// extracted by mini-css-extract-plugin
export var root = "PlasmicBlogPost-module--root--vJpz1";
export var root__long = "PlasmicBlogPost-module--root__long--1eQ84";
export var freeBox__zOKhC = "PlasmicBlogPost-module--freeBox__zOKhC--3vlwd";
export var img__pBiEj = "PlasmicBlogPost-module--img__pBiEj--cnbNa";
export var __wab_imgSpacer = "PlasmicBlogPost-module--__wab_img-spacer--Zkby0";
export var freeBox__o0G0 = "PlasmicBlogPost-module--freeBox__o0G0--3Ch4R";
export var freeBox__long__o0G0MlbW = "PlasmicBlogPost-module--freeBox__long__o0G0MlbW--er8Wu";
export var slotTitle = "PlasmicBlogPost-module--slotTitle--YMQxe";
export var slotAuthor = "PlasmicBlogPost-module--slotAuthor--1gJX4";
export var slotDescription = "PlasmicBlogPost-module--slotDescription--3LK_k";
export var slotDescription__long = "PlasmicBlogPost-module--slotDescription__long--1JBVA";