// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureItem-module--root--1MAH4";
export var freeBox__fv8YW = "PlasmicFeatureItem-module--freeBox__fv8YW--2Jhcx";
export var freeBox__lfOpL = "PlasmicFeatureItem-module--freeBox__lfOpL--3_-JC";
export var freeBox__o2IbR = "PlasmicFeatureItem-module--freeBox__o2IbR--2ilzf";
export var freeBox__iMuX = "PlasmicFeatureItem-module--freeBox__iMuX--1qlfy";
export var freeBox__vql9G = "PlasmicFeatureItem-module--freeBox__vql9G--ioPYD";
export var freeBox__yIeOf = "PlasmicFeatureItem-module--freeBox__yIeOf--32e-x";
export var freeBox__gJbji = "PlasmicFeatureItem-module--freeBox__gJbji--2SWn7";
export var freeBox___1WLv = "PlasmicFeatureItem-module--freeBox___1WLv--3LGaU";
export var linkButton = "PlasmicFeatureItem-module--linkButton--JMszT";